@font-face {
  font-family: "SF_Pro_Display_Black";
  src: local("SF_Pro_Display_Black"),
   url("./assets/fonts/FontsFree-Net-SFProDisplay-Black.ttf") format("truetype");
}
@font-face {
  font-family: "SF_Pro_Display_Bold";
  src: local("SF_Pro_Display_Bold"),
   url("./assets/fonts/FontsFree-Net-SFProDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SF_Pro_Display_SEMI_BOLD";
  src: local("SF_Pro_Display_SEMI_BOLD"),
   url("./assets/fonts/SF-Pro-Display-Semibold.otf") format("truetype");
}
@font-face {
  font-family: "SF_Pro_Display_MEDIUM";
  src: local("SF_Pro_Display_MEDIUM"),
   url("./assets/fonts/SF-Pro-Display-Medium.otf") format("truetype");
}
@font-face {
  font-family: "SF_Pro_Display_REGULAR";
  src: local("SF_Pro_Display_REGULAR"),
   url("./assets/fonts/SF-Pro-Display-Regular.otf") format("truetype");
}
@font-face {
  font-family: "SF_Pro_Display_Heavy";
  src: local("SF_Pro_Display_Heavy"),
   url("./assets/fonts/FontsFree-Net-SFProDisplay-Heavy-1.ttf") format("truetype");
}
@font-face {
  font-family: "PlusJakartSans_Medium";
  src: local("PlusJakartSans_Medium"),
   url("./assets/fonts/PlusJakartaSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PlusJakartSans_Regular";
  src: local("PlusJakartSans_Regular"),
   url("./assets/fonts/PlusJakartaSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PlusJakartSans_SemiBold";
  src: local("PlusJakartSans_SemiBold"),
   url("./assets/fonts/PlusJakartaSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "PlusJakartSans_Bold";
  src: local("PlusJakartSans_Bold"),
   url("./assets/fonts/PlusJakartaSans-Bold.ttf") format("truetype");
}

body {
  margin: 0%;
}
